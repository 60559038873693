<template>
  <div class="router-wrap">
    <div class="aboutkangcunbox">
      <div class="aboutkangcunmeteor">
        <div class="aboutkangcunmeteorchild"></div>
        <div class="aboutkangcunmeteorchild"></div>
        <div class="aboutkangcunmeteorchild"></div>
      </div>
      <div class="aboutchair"></div>
      <div class="aboutintroduction-box">
        <router-link
          to="/AboutKangCunDetails"
          class="aboutintroduction"
          ref="content"
        >
          <div class="aboutintroductiontop">{{ ductiondata.subTitle }}</div>
          <p class="aboutintroductionline"></p>
          <div class="aboutintroductionval" v-html="ductiondata.val"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { articleList } from "@/api/common.js";
export default {
  name: "AboutKangCun",
  data() {
    return {
      ductiondata: {
        top: "",
        val: "",
        subTitle: "",
      },
    };
  },
  props: {
    activeIndV: [Number],
  },
  computed: {
    costRequest() {
      return {
        organizationType: this.activeTab,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.getarticleList();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    activeIndV: function (val) {
      if (val == 1) {
        // console.log("test===",)
        this.$refs.content.$el.className = "aboutintroduction translate";
      } else {
        this.$refs.content.$el.className = "aboutintroduction";
      }
    },
  },
  mounted() {
    this.getarticleList();
  },
  methods: {
    getarticleList() {
      articleList({
        lang: sessionStorage.getItem("langType"),
        title: "康存数科集团简介",
      }).then((res) => {
        // this.ductiondata.top = res.data[0].subTitle;
        // this.ductiondata.val = res.data[0].content_markdown_source;
        this.ductiondata.val = res.data[0].content;
        this.ductiondata.subTitle = res.data[0].subTitle;
      });
    },
  },
};
</script>
<style lang="scss" scoped >
.aboutkangcunbox {
  width: 100%;
  height: 100%;
  background: url(../assets/imgs/aboutkangcun/aboutback.jpg) no-repeat center
    center;
  //background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  .aboutkangcunmeteor {
    width: 62%;
    height: 68%;
    position: absolute;
    right: 0;
    top: 0;
    .aboutkangcunmeteorchild:nth-child(1) {
      width: 50px;
      height: 40px;
      background: url(../assets/imgs/aboutkangcun/meteorone.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0%;
      right: 10%;
      animation: run-right-rightone 10s infinite linear,
        run-right-topone 10s infinite cubic-bezier(0.36, 0.41, 0.67, 0.63);
    }
    .aboutkangcunmeteorchild:nth-child(2) {
      width: 54px;
      height: 42px;
      background: url(../assets/imgs/aboutkangcun/meteortwo.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0%;
      right: 10%;
      animation: run-right-righttwo 5s infinite linear,
        run-right-toptwo 5s infinite cubic-bezier(0.36, 0.41, 0.67, 0.63);
    }
    .aboutkangcunmeteorchild:nth-child(3) {
      width: 40px;
      height: 30px;
      background: url(../assets/imgs/aboutkangcun/meteorthree.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 0%;
      right: 10%;
      animation: run-right-rightthree 7s infinite linear,
        run-right-topthree 7s infinite cubic-bezier(0.36, 0.41, 0.67, 0.63);
    }
  }
  .aboutchair {
    width: 60%;
    height: 23%;
    position: absolute;
    left: 50%;
    margin-left: -30%;
    bottom: 10%;
    background: url(../assets/imgs/aboutkangcun/backchair.png) no-repeat;
    background-size: 100% 100%;
  }
  .aboutintroduction-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
  }
  .aboutintroduction {
    // 940,344
    // 800,234
    width: 940px;
    height: 385px;
    position: absolute;
    right: 20px;
    top: 31%;
    background: rgba(0, 71, 157, 0.6);
    padding: 0 65px;
    //transform: translateX(-500px);
    opacity: 0;
    &.translate {
      transition: all 0.3s ease-in-out 0.4s;
      opacity: 1;
      .aboutintroductiontop {
        transition: all 0.4s ease-in-out 0.4s;
        transform: translateX(0px);
      }
      .aboutintroductionline {
        transition: all 0.4s ease-in-out 0.4s;
        transform: scale(1, 1);
      }
      .aboutintroductionval {
        transition: all 0.4s ease-in-out 0.4s;
        transform: translateY(0px);
      }
    }
    .aboutintroductiontop {
      transform: translateX(-800px);
      height: 97px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: white;
      text-shadow: 5px 15px 15px rgba(1, 90, 183, 1);
    }
    .aboutintroductionline {
      height: 1px;
      background: #fff;
      transform: scale(0, 1);
    }
    .aboutintroductionval {
      // transform: translateY(600px);
      padding-top: 15px;
      // height: calc(100% - 117px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      line-height: 48px;
      font-size: 16px;
      color: white;
    }
  }
}

// .aboutintroductionval::-webkit-scrollbar {
//   /*滚动条整体样式*/
//   width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
// }
// .aboutintroductionval::-webkit-scrollbar-thumb {
//   /*滚动条里面小方块*/
//   border-radius: 3px;
//   background: rgba(14, 27, 140, 0.35);
// }
// .aboutintroductionval::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   border-radius: 3px;
//   background: rgba(14, 27, 140, 0.1);
// }
@keyframes run-right-rightone {
  0% {
    right: 10%;
    opacity: 0;
  }
  25% {
    right: 35%;
    opacity: 1;
  }
  50% {
    right: 60%;
    opacity: 0;
  }
  100% {
    right: 60%;
    opacity: 0;
  }
}
@keyframes run-right-righttwo {
  0% {
    right: 30%;
    opacity: 0;
  }
  25% {
    right: 55%;
    opacity: 1;
  }
  50% {
    right: 80%;
    opacity: 0;
  }
  100% {
    right: 80%;
    opacity: 0;
  }
}
@keyframes run-right-rightthree {
  0% {
    right: 5%;
    opacity: 0;
  }
  25% {
    right: 30%;
    opacity: 1;
  }
  50% {
    right: 55%;
    opacity: 0;
  }
  100% {
    right: 55%;
    opacity: 0;
  }
}
@keyframes run-right-topone {
  0% {
    top: 0%;
    opacity: 0;
  }
  25% {
    top: 20%;
    opacity: 1;
  }
  50% {
    top: 40%;
    opacity: 0;
  }
  100% {
    top: 40%;
    opacity: 0;
  }
}
@keyframes run-right-toptwo {
  0% {
    top: 0%;
    opacity: 0;
  }
  25% {
    top: 20%;
    opacity: 1;
  }
  50% {
    top: 40%;
    opacity: 0;
  }
  100% {
    top: 40%;
    opacity: 0;
  }
}
@keyframes run-right-topthree {
  0% {
    top: 0%;
    opacity: 0;
  }
  25% {
    top: 20%;
    opacity: 1;
  }
  50% {
    top: 40%;
    opacity: 0;
  }
  100% {
    top: 40%;
    opacity: 0;
  }
}
</style>